import { green } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import 'fontsource-roboto';
import { React, useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { browserHistory } from 'react-router';
import AppHeader from './AppHeader';
import Contact from './Contact';
import Disclaimer from './Disclaimer';
import Documents from './Documents';
import Footer from './Footer';
import Forms from './Forms';
import Home from './Home';
import Tickets from './Tickets';
import Umrah from './features/umrah';
import UmrahPackageDetail from './features/umrah/components/UmrahPackgeDetail';
import Hajj from './features/hajj';
import HajjPackageDetail from './features/hajj/components/HajjPackgeDetail';
import HajjEconomyReservation from './Reservation/HajjEconomy';
import HajjDeluxeReservation from './Reservation/HajjDeluxe';
import HajjSuperDeluxeReservation from './Reservation/HajjSuperDeluxe';
import LastRamadan from './Reservation/LastRamadan';
import March from './Reservation/March';
import Christmas from './Reservation/Christmas';
import Thanksgiving from './Reservation/Thanksgiving';
import Winter from './Reservation/Winter';
import Summer from './Reservation/Summer';

// Page title {packageDetails.name}, {packageDetails.pax} persons/room - error 
// Contact page
// Reservation pages (onSoft)

const defaultTheme = createMuiTheme({
  palette: {
    primary: green //#4caf50  fruit salad
    // Background #e8f5e9  Frosted Mint
  }
})



function App() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (



    <div className="App" style={{ paddingTop: "50px" }}>
      <ThemeProvider theme={defaultTheme}>

        <Router history={browserHistory}>
          <AppHeader />
          <Route exact path="/" component={Home}></Route>
          <Route path="/home" component={Home}></Route>
          <Route exact path="/umrah-package" component={Umrah}></Route>
          <Route exact path="/umrah-package/detail/:packageName" component={UmrahPackageDetail}></Route>
          <Route exact path="/hajj-package" component={Hajj}></Route>
          <Route exact path="/hajj-package/detail/:packageName" component={HajjPackageDetail}></Route>
          <Route path="/tickets" component={Tickets}></Route>
          <Route path="/forms" component={Forms}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/disclaimer" component={Disclaimer}></Route>
          <Route path="/documents" component={Documents}></Route>
          <Route path="/Reservation/HajjEconomy" component={HajjEconomyReservation}></Route>
          <Route path="/Reservation/HajjDeluxe" component={HajjDeluxeReservation}></Route>
          <Route path="/Reservation/HajjSuperDeluxe" component={HajjSuperDeluxeReservation}></Route>
          <Route path="/Reservation/Thanksgiving" component={Thanksgiving}></Route>
          <Route path="/Reservation/Christmas" component={Christmas}></Route>
          <Route path="/Reservation/Winter" component={Winter}></Route>
          <Route path="/Reservation/Summer" component={Summer}></Route>
          <Route path="/Reservation/March" component={March}></Route>
          <Route path="/Reservation/LastRamadan" component={LastRamadan}></Route>


          <Footer />
        </Router>

      </ThemeProvider>
    </div>
  );
}

export default App;
