import React from 'react';


  export const CompanyName = () => {
    const CompanyName = 'For Hajj'
    return (
        
        <span title="For Hajj, Hajj and Umrah packages from USA">{CompanyName}</span>
        
    )
}