import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import SendIcon from '@material-ui/icons/Send';
import emailjs, { init } from 'emailjs-com';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { CompanyName } from './CompanyName';
init("user_Tl0SGyQGCyQlgnLGgTTER");

const validationSchema = yup.object().shape({
  name: yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!"),
  company: yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  phone: yup.string()
    .min(2, "Too Short!")
    .max(10, "Too Long!"),

})








const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',

    },
  },
}));

const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
}



export default function Contact() {
  const classes = useStyles();
  const [emailSent, setEmailSent] = useState(false);


  const handleSubmit = (values) => {
    console.log(values)

    emailjs.send("service_161dv1i", "template_5e7ptvz", values);
    setEmailSent(true)
  }
  return (
    <Container>
      <div><h1><b><CompanyName></CompanyName></b></h1></div>
      { !emailSent &&
        <Grid container spacing={3}>

          <Grid item xs={7}>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {
                ({ errors }) => (
                  <Form className={classes.root} autoComplete="off">

                    <div>
                      <Field as={TextField} fullWidth name="name" required label="Name:" type="text" autoComplete="off" />
                      <ErrorMessage name="name" />
                      <Field as={TextField} fullWidth name="phone" required label="Phone:" type="number" autoComplete="off" />
                      <ErrorMessage name="phone" />
                      <Field as={TextField} fullWidth name="email" required label="Email:" type="" autoComplete="off" />
                      <ErrorMessage name="email" />
                      <Field as={TextField} fullWidth name="message" required label="Message:" type="text" multiline rows='5' autoComplete="off" />
                      <ErrorMessage name="message" />
                      <Button fullWidth variant="contained" type="submit" color="primary" ><SendIcon></SendIcon>&nbsp;&nbsp;<b>Submit</b></Button>
                    </div>

                  </Form>
                )
              }

            </Formik>

          </Grid>


          <Grid item xs={5}>

            <Typography><companyName></companyName></Typography>

            <Typography variant="body1">If you have questions or comments, feel free to contact us.</Typography>
            <Typography><h4>For Hajj</h4></Typography>
            <Typography variant="body1">Phone: 732-234-3628 & 732-986-2896</Typography>
            <Typography variant="body1">E-Mail: toursanywhere@aol.com</Typography>
            {/* <Typography variant="body1">5 PINYON Drive,<br/>
Old Bridge, NJ 08857 and 250 Park Ave,<br/>
7th Floor Suite 7053, New York, 10177</Typography> */}
            <Typography style={{ paddingTop: '15px' }}><Button fullWidth variant="contained" id="btnSubmit" color="primary" href="tel:7324792711" label=""><PhonelinkRingIcon></PhonelinkRingIcon>&nbsp;&nbsp;<b>Call Now</b> </Button></Typography>

          </Grid>

        </Grid>
      }
      {emailSent &&

        <div>Thank you, we will be in touch soon</div>
      }
    </Container>

  );
}

