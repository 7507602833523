import { Box, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';


const today = new Date();

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const Notes = () => {

    const classes = useStyles();

    return (
        <div style={{ marginTop: "1rem" }}>
            <Paper style={{ backgroundColor: "#e8f5e9" }} variant="outlined">
                <Typography variant="h6" align="center" gutterBottom style={{ paddingTop: '15px', color: "#1b5e20" }}>Notes</Typography>
                <Box pl={5} pr={5}>
                    <Typography variant="body2" display="block" color="textSecondary" align="center">Additional charges apply when departing from cities other than New York.</Typography>
                    <Typography variant="body2" display="block" color="textSecondary" align="center">Price does not include Zabiha" (Qurbani) $150.00 and the Hajj fee $475.00</Typography>
                    <Typography variant="body2" gutterBottom display="block" color="textSecondary" align="center">No refund will be issued after March 01, {today.getFullYear()}.</Typography>
                </Box>
                <Box pl={5} pr={5} pt={3}>
                    <Typography variant="body1" gutterBottom display="block" align="center">Mail the following items to:</Typography>
                    <Typography variant="body2" display="block" align="center">(Tours Anywhere For Hajj)</Typography>
                    {/* <Typography variant="body2" display="block" align="center"><address>5 PINYON Drive,Old Bridge, NJ 08857.</address></Typography> */}
                </Box>



                <Box pl={5} pr={5} pt={2}>
                    <TableContainer component={Container} size="small">
                        <Table className={classes.table} size="small">

                            <TableHead>

                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Completed and signed Hajj registration form.</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">2 Luggage only very everyone no more than 50 pounds each if more charge for each bus $100.00 between Makkah Madinah,Madinah Makkah or Airport and each city.</Typography></TableCell>

                                </TableRow>

                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">A check in the amount of $2,500/per person payable to: (Tours Anywhere For Hajj) (Please note – non-refundable after airline ticket has been reserved).</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">U.S. Passport valid for at least 6 months from travel date.</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">If not a U.S. Citizen, then a Passport of your Citizenship, valid for at least 6 months from travel date, along with a photocopy (both sides) of your Alien Registration (Green) Card.</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Three Passport Size Photos with White Background.</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Pilgrims with non-Islamic names must have a statement from any Islamic center showing their conversion to Islam.</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Sisters who are not married and do not have “Mohram” must submit a “No Objection” letter signed and notarized by a First Degree relative.</Typography></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Wives accompanies by their husbands must submit a copy of their marriage certificate.</Typography></TableCell>

                                </TableRow>

                                <TableRow>
                                    <TableCell><Typography variant="body2" color="textSecondary">Health Certificate against Meningitis.</Typography></TableCell>

                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </div>
    )
}

export default Notes
