import React from 'react'

function Developed() {
    
    return (
        <span>
            Web Design & Development by <a href="https://www.webdesignexpressions.net/" target="_blank" rel="noreferrer" style={{textDecorationLine: 'none'}}>Web Design Expressions, Inc</a>
        </span>
    )
}

export default Developed
