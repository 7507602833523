import image01 from '../../Images/Al-Masjid-an-Nabawi.jpg'
import image02 from '../../Images/Enconomy.jpg'
//import image03 from '../../Images/Makka-2.jpg'
import image04 from '../../Images/Makka-3.jpg'
import image05 from '../../Images/Makka.jpg'
import image06 from '../../Images/Makka-4.jpg'

const images = [image01, image02, image04, image05, image06]
const PackageImage = ( index) => {
    let newIndex;
    const availableImagesCount = images.length;
    if (index < availableImagesCount) {
        newIndex = index;
    } else {
        newIndex = index % availableImagesCount;
    }
    return (
        images[newIndex]
    )
}

export default PackageImage
