import { AppBar } from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { useHistory } from "react-router-dom";


function AppHeader() {

  const history = useHistory()
  const [SelectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  };
  return (
    <div>
      <AppBar position="fixed" centered style={{ backgroundColor: "#e8f5e9" }}>
        <Tabs value={SelectedTab} onChange={handleChange} variant="fullWidth" style={{ color: '#1b5e20' }}>
          <Tab label="Home" title="Hajj and Umrah Packages" onClick={() => history.push('/home')} style={{ textTransform: 'none' }} />
          <Tab label="Hajj" title="Hajj Packages" onClick={() => history.push('/hajj-package')} style={{ textTransform: 'none' }} />
          <Tab label="Umrah" title="Umrah Packages" onClick={() => history.push('/umrah-package')} style={{ textTransform: 'none' }} />
          <Tab label="Flights" title="Flights" onClick={() => history.push('/tickets')} style={{ textTransform: 'none' }} />
          <Tab label="Forms" title="Hajj Forms" onClick={() => history.push('/forms')} style={{ textTransform: 'none' }} />
          <Tab label="Contact" title="Contact For Hajj" onClick={() => history.push('/contact')} style={{ textTransform: 'none' }} />
        </Tabs>
      </AppBar>
    </div>
  )
}

export default AppHeader
