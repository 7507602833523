import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
//import Slide1 from './Images/01.jpg';
//import Slide11 from './Images/011.jpg';
import Slide12 from './Images/012.jpg';
import Slide2 from './Images/02.jpg';
//import Slide3 from './Images/03.jpg';
//import Slide4 from './Images/04.jpg';
//import Slide5 from './Images/05.jpg';
import Slide6 from './Images/06.jpg';
//import Slide7 from './Images/07.jpg';
//import Slide8 from './Images/08.jpg';

const slideImages = [
  // 'images/01.jpg',
  'images/02.jpg',
  // 'images/03.jpg',
  // 'images/04.jpg',
  // 'images/05.jpg',
  'images/06.jpg',
  // 'images/07.jpg',
  //'images/08.jpg',
  'images/09.jpg',
  'images/10.jpg',

];


const Slider = () => {


  return (

    <div className="slide-container">
      <Slide>
        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
            <span><img src={Slide1} alt="package" style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}
        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
            <span><img src={Slide2} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div>
        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
            <span><img src={Slide3} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}
        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[3]})` }}>
            <span><img src={Slide4} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}

        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[4]})` }}>
            <span><img src={Slide5} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}

        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[5]})` }}>
            <span><img src={Slide6} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div>

        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[6]})` }}>
            <span><img src={Slide7} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}

        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[7]})` }}>
            <span><img src={Slide8} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}

        {/* <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[8]})` }}>
            <span><img src={Slide11} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div> */}

        <div className="each-slide">
          <div style={{ 'backgroundImage': `url(${slideImages[9]})` }}>
            <span><img src={Slide12} alt="" fluid={true} style={{ width: "100%", height: "auto" }} /></span>
          </div>
        </div>

      </Slide>
    </div>
  )
}

export default Slider
