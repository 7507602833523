import { Button, Container } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CompanyName } from './CompanyName';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function Documents() {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Helmet>
        <title>Hajj Documents</title>
      </Helmet>
      <h4><CompanyName></CompanyName> Required Documents</h4>
      <div className={classes.root}>
        <ExpansionPanel rounded={true} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading} color="primary">1- Registration form</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              Completed and signed Hajj registration form.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>




        <ExpansionPanel rounded={true} expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading} color="primary">2- Passport</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              U.S. Passport valid for at least 6 months from travel date.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel rounded={true} expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading} color="primary">3- Green Card</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              If not a U.S. Citizen, then a Passport of your Citizenship, valid for at least 6 months from travel date, along with a photocopy (both sides) of your Alien Registration (Green) Card.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel rounded={true} expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography className={classes.heading} color="primary">4- Photos</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              Three Passport Size Photos with White Background.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>


        <ExpansionPanel rounded={true} expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography className={classes.heading} color="primary">5- Statement</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              Pilgrims with non-Islamic names must have a statement from any Islamic center showing their conversion to Islam.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>







        <ExpansionPanel rounded={true} expanded={expanded === 'panel8'} onChange={handleChange('panel8')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography className={classes.heading} color="primary">6- Mahram Letter</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              Wives accompanies by their husbands must submit a copy of their marriage certificate.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>


        <ExpansionPanel rounded={true} expanded={expanded === 'panel9'} onChange={handleChange('panel9')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography className={classes.heading} color="primary">7- Meningitis</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              Health Certificate against Meningitis.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>


        <ExpansionPanel rounded={true} expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ backgroundColor: "#e8f5e9" }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading} color="primary">8- Payment</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography body1>
              <Grid Container>
                <Grid item lg={10} md={10} xs={12} sm={12}>A check in the amount of $2,500/per person payable to: (Tours Anywhere For Hajj) (Please note – non-refundable after airline ticket has been reserved).</Grid>
                <Grid item lg={2} md={2} xs={12} sm={12}><Button variant="contained" color="primary" size="large">Make a Payment</Button></Grid>
              </Grid>


            </Typography>
          </ExpansionPanelDetails>

        </ExpansionPanel>

      </div>

    </Container>
  )
}

export default Documents
