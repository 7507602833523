


const firebaseConfig = {
    apiKey: "AIzaSyA0Qw2E_ZtjtOzVXZpiK-6Y54bsjgPPVwM",
    authDomain: "forhajjnow.firebaseapp.com",
    databaseURL: "https://forhajjnow-default-rtdb.firebaseio.com",
    projectId: "forhajjnow",
    storageBucket: "forhajjnow.appspot.com",
    messagingSenderId: "532600289334",
    appId: "1:532600289334:web:acb29de5b7e0e86ba44097"
  };
  
  export default firebaseConfig;
  
  // forhajj2hajonsoft@gmail.com
  