import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Helmet } from 'react-helmet';
import firebase from "../../firebaseapp";
import PackageCard from './components/PackageCard';

function Hajj() {
  const [hajjPackages, setHajjPackages] = useState([])
  useEffect(() => {
    firebase.database().ref("protected/onlinePackage").once('value', snapshot => {
      setHajjPackages(Object.values(snapshot.toJSON()).filter(x=> x.gender === 'Hajj'))
    })
  }, [])

  return (
    <Container>
      <Helmet>
        <title>Hajj Packages</title>
      </Helmet>

    <div style={{marginTop: '5rem'}}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          {hajjPackages && hajjPackages.map((p,i) =>
            <Grid item xs={12} lg={4} sm={12} md={6} >
              <Animated animationIn="bounceInLeft" isVisible={true}>
                <PackageCard detail={p} index={i} key={p.name} />
              </Animated>
            </Grid>
          )}
        </Grid>
    </div>
    </Container>
  )
}

export default Hajj
