import React from 'react';
import PackageDetails from './PackageDetails'


function HajjPackageDetail() {

  return (
    <PackageDetails />
  )
}

export default HajjPackageDetail


