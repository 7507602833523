import { Container } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CompanyName } from './CompanyName';

function Tickets() {
    return (
        <Container>
            <Helmet>
                <title>Tickets</title>
            </Helmet>
            <div><h4><CompanyName></CompanyName> Tickets</h4></div>
            <iframe title="Reserve a Ticket" width="100%" height="500px" frameBorder="0" src="http://www.farebuzz.com/affiliates/Default.aspx?tabid=8157&FpAffiliate=Toursanywhere116788&caID=116788&FpSub=&" allowfullscreen></iframe>

        </Container>
    )
}

export default Tickets
