import { Container, Typography, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Animated } from "react-animated-css";
import { Helmet } from 'react-helmet';
import Abdulrahman from './Images/Imam-Abdulrahman.jpg';
import Azib from './Images/Imam-Azib-Suaib.jpg';
import Shazad from './Images/Mufty-Imam-Shazad.jpg';
import Ibymi from './Images/Shiekh-Deen-Ibymi.jpg';
import Elnakib from './Images/Shiekh-Syed-Elnakib.jpg';
import Amin from './Images/Shikh-Mir-Amin.jpg';
import Slider from './Slider';
import Hajj from './Images/Hajj.png';
import Umrah from './Images/Umrah.png';
import RamadanUmrah from './Promo/ramadanUmrah';
import { BrowserRouter as Router } from "react-router-dom";
import { useHistory } from "react-router-dom";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },

// }));

function Home() {
  // const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Router>
        <Slider />
        <Container style={{ marginTop: "2rem" }}>
          <Helmet>
            <title>For Hajj | Home</title>


          </Helmet>


          <Grid container spacing={3}>
            <Grid item xs={12} lg={4} sm={12} md={4}>
              <Typography variant="h5" align="center">Welcome to</Typography>
              <Typography variant="h3" align="center">For Hajj</Typography>
              <Typography variant="body1" align="center" color="textSecondary">Hajj and Umrah packages from USA</Typography>
            </Grid>
            <Grid item xs={12} lg={8} sm={12} md={8} align="center">
              {/* <img src={Logo} alt="" style={{ width: '100%', height: 'auto'}} /> */}
              <Animated animationIn="flipInY" isVisible={true}>
                <Grid container spacing={3}>

                  <Grid item xs={2} lg={2} sm={2} md={2}>
                    <div><img src={Azib} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Imam Azib Suaib</Typography> */}
                  </Grid>
                  <Grid item xs={2} lg={2} sm={2} md={2}>

                    <div><img src={Amin} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Shikh Mir Amin</Typography> */}

                  </Grid>
                  <Grid item xs={2} lg={2} sm={2} md={2}>

                    <div><img src={Shazad} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Mufty Imam Shazad</Typography> */}

                  </Grid>


                  <Grid item xs={2} lg={2} sm={2} md={2}>
                    <div><img src={Abdulrahman} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Imam Abdulrahman</Typography> */}
                  </Grid>


                  <Grid item xs={2} lg={2} sm={2} md={2}>

                    <div><img src={Elnakib} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Shiekh Syed Elnakib</Typography> */}

                  </Grid>


                  <Grid item xs={2} lg={2} sm={2} md={2}>

                    <div><img src={Ibymi} alt="" style={{ width: '100%', height: 'auto', border: '2px solid #4caf50', borderRadius: '50%' }} /></div>
                    {/* <Typography variant="body1">Shiekh Deen Ibymi</Typography> */}

                  </Grid>
                </Grid>

              </Animated>


            </Grid>

          </Grid>


          <Grid container spacing={0} style={{ marginTop: '15px' }}>
            <Grid item xs={12} lg={6} sm={12} md={6} align="center" style={{ backgroundColor: '#e8f5e9', color: '#000000', border: '1px solid #4caf50' }}>

              <Link onClick={() => history.push('/hajj-package')} style={{ cursor: 'pointer' }} title="">
                <img src={Hajj} alt="" />
                <h3>Hajj Packages</h3></Link>
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={6} align="center" style={{ backgroundColor: '#e8f5e9', color: '#000000', border: '1px solid #4caf50' }}>
              <Link onClick={() => history.push('/umrah-package')} style={{ cursor: 'pointer' }} title="">
                <img src={Umrah} alt="" />
                <h3>Umrah Packages</h3></Link>

            </Grid>

          </Grid>

          <Grid>

            <RamadanUmrah />

          </Grid>

        </Container>
      </Router>
    </React.Fragment>

  )
}

export default Home
