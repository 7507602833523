import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import Hajjletter from './letter.pdf';
import visa from './visa.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function Forms() {
  const classes = useStyles();

  return (
    <Container style={{ marginTop: "15px" }}>
      <div><h4>Hajj Forms</h4></div>
      <Helmet>
        <title>Hajj Forms</title>
      </Helmet>
      <Grid container spacing={3}>

        <Grid item xs={12} lg={6} sm={12} md={6}>
          <a href={"./documents"} style={{ textDecoration: "none" }} title="For Hajj Required Documents"><Paper className={classes.paper} style={{ backgroundColor: '#4caf50' }}><h4>Required Documents</h4></Paper></a>
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          <a href={visa} style={{ textDecoration: "none" }} title="For Hajj Visa Application"><Paper className={classes.paper} style={{ backgroundColor: '#4caf50' }}><h4>Visa Application</h4></Paper></a>
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          <a href={Hajjletter} style={{ textDecoration: "none" }} title="For Hajj Muhram Letter"><Paper className={classes.paper} style={{ backgroundColor: '#4caf50' }}><h4>Muhram Letter</h4></Paper></a>
        </Grid>

        <Grid item xs={12} lg={6} sm={12} md={6}>

          <a href={"/disclaimer"} style={{ textDecoration: "none" }} title="For Hajj Disclaimer"><Paper className={classes.paper} style={{ backgroundColor: '#4caf50' }}><h4>Disclaimer</h4></Paper></a>
        </Grid>

      </Grid>
    </Container>
  )
}

export default Forms
