import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import anjumhotels1 from '../Images/DarAlEiman-1.jpg';
import anjumhotels2 from '../Images/anjumhotels2.jpg';
import anjumhotels3 from '../Images/DarAlEiman-2.jpg';
import AlNokhba1 from '../Images/RuaAlHijrah-1.jpg';
import AlNokhba2 from '../Images/AlNokhba2.jpg';
import AlNokhba3 from '../Images/RuaAlHijrah-2.jpg';


import Divider from '@material-ui/core/Divider';

function ramadanUmrah() {
  return (
    <div style={{ paddingTop: '15px' }}>
      <Typography variant="h3" align="center" color="error" style={{ paddingBottom: '15px' }}>Ramadan Last 15 days Makkah Umrah<br />2025 - 1446 </Typography>


      <Grid container>

        <Grid item xs={12} lg={3} sm={12} md={3}>

          <Grid align="center"><img src={anjumhotels2} alt="ramadan umrah from usa" title='Ramadan Last 15 days Umrah from USA 2025 - 1446' /></Grid>

          <Grid align="center"><img src={anjumhotels1} alt="ramadan umrah from usa, last 15 days" title='Ramadan Last 15 days Umrah from USA, 5 Stars Al Shohada Hotel - Makkah' /></Grid>

          <Grid align="center"><img src={anjumhotels3} alt="ramadan umrah from usa, last 15 days" title='Ramadan Last 15 days Umrah from USA, 5 Stars Al Shohada Hotel - Makkah' /></Grid>


        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6}>

          <Grid align="center">

            <Typography variant="h5" align="center">3 Stars Hotel Rua Al Hijrah - Medina</Typography>
            <Typography variant="h5" align="center">5 Stars Prestige Hotel - Makkah</Typography>
            <Typography variant="overline" align="center" >Prices for Umrah Visa/ Per Person</Typography>
            <Typography variant="body1" align="center" ><span style={{ color: 'red' }}>$4500.00</span> - <span>4 Persons/ Room</span></Typography>
            <Typography variant="body1" align="center" ><span style={{ color: 'red' }}>$4890.00</span> - <span>3 Persons/ Room</span></Typography>
            <Typography variant="body1" align="center" ><span style={{ color: 'red' }}>$5980.00</span> - <span>2 Persons/ Room</span></Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            <Typography variant="h5" align="center">Accommodation</Typography>
            <Typography variant="body1" align="center" ><span style={{ color: 'red' }}>4 </span><span>Nights in Madinah First <br />Rua Al Hijrah Hotel Medina - <small>262 feet from Al-Masjid an-Nabawi</small><br /> From March 16, 2025 - Ramadan 17, 1446</span></Typography>
            <Typography variant="body1" align="center" ><span style={{ color: 'red' }}>12 </span><span>Nights in Makkah<br />Prestige Hotel Makkah (Eylaf Almashaar)<br />From March 20, 2025 - Ramadan 21, 1446</span></Typography>

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            <Typography variant="h5" align="center">Flights</Typography>
            <Typography variant="body1" align="center" >Qatar Airways</Typography>
            <Typography variant="body1" align="center" >Qatar Airways Itinerary:</Typography>
            <div style={{paddingLeft: '10px'}}>
            <Typography variant="body1" align="left" >1 QR 16 Mar Sunday JFK DOH *  Departur 11:25 Arrival 06:50 17 Mar</Typography>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <Typography variant="body1" align="left" >2 QR 17 Mar Monday DOH MED * Departur 15:30 Arrival 18:00 17 Mar</Typography>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <Typography variant="body1" align="left" >3 QR 02 Apr Wednesday JED DOH * Departure 11:25 Arrival 13:55 02 Apr<br /></Typography>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            <Typography variant="body1" align="left" >4 QR 02 Apr Wednesday DOH JFK * Departure 15:15 Arrival 22:30 02 Apr<br /></Typography>
            </div>
            <Typography variant="h5" align="center" >Package Include: </Typography>
            <Typography variant="body1" align="center" >Return Flights</Typography>
            <Typography variant="body1" align="center">Visa</Typography>
            <Typography variant="body1" align="center">Accommodation</Typography>
            <Typography variant="body1" align="center" color="error">Include Sohour Only</Typography>
            <Typography variant="body1" align="center" color="error">Total 2 Jum'ah prayer</Typography>

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            <Typography variant="h5" align="center">Ziyarah</Typography>
            <Typography variant="body1" align="center" >Mazarat Madinah: Mount Uhud, Masjid Quba</Typography>
            <Typography variant="body1" align="center">Mazarat Makkah: Thour Mountain, Hira Cave</Typography>
            <Typography variant="body1" align="center" ><span>Kids Under Age 7: </span><span style={{ color: 'red' }}>$2700.00 </span><span>(No Separate Bed in Hotel) </span></Typography>
            <Typography variant="body1" align="center" color="error">Must be Vaccinated</Typography>

            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            <Typography variant="h5" align="center">Requirements</Typography>
            <Typography variant="body1" align="center" >Two Colored Passport Size Photos</Typography>
            <Typography variant="body1" align="center">Meningitis Vaccination and Covid-19</Typography>
            <Typography variant="body1" align="center" >Women Under age 45 No need for Mahram</Typography>
            <Typography variant="body1" align="center">Children Under age 18 need Notarized letter with company name "Tours Anywhere" and Birth Certificate</Typography>

          </Grid>


        </Grid>
        <Grid item xs={12} lg={3} sm={12} md={3}>


          <Grid align="center"><img src={AlNokhba2} alt="ramadan umrah from usa" title='Ramadan Last 15 days Umrah from USA 2025 - 1446' /></Grid>
          <Grid align="center"><img src={AlNokhba1} alt="ramadan umrah from usa, al nokhba royal madinah" title='Ramadan Last 15 days Umrah from USA, 3 Stars Hotel Rua Al Hijrah - Madinah' /></Grid>
          <Grid align="center"><img src={AlNokhba3} alt="ramadan umrah from usa, al nokhba royal madinah" title='Ramadan Last 15 days Umrah from USA, 3 Stars Hotel Rua Al Hijrah - Madinah' /></Grid>

        </Grid>


      </Grid>


    </div>
  )
}

export default ramadanUmrah