import { Box, Container, Link, Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import firebase from '../../../firebaseapp';
import Nabawi from '../../../Images/Al-Masjid-an-Nabawi.jpg';
//import image03 from '../../../Images/Makka-2.jpg';
import { default as image04, default as kaaba } from '../../../Images/Makka-3.jpg';
import image06 from '../../../Images/Makka-4.jpg';
import image05 from '../../../Images/Makka.jpg';
import Notes from '../../Notes';
import PackageFeatures from './PackageFeatures';
import PackageSchedule from './PackageSchedule';

const PackageDetails = () => {

    const { packageName } = useParams();

    const [detail, setDetail] = useState({})
    useEffect(() => {
        firebase.database().ref("protected/onlinePackage").once('value', snapshot => {
            setDetail(Object.values(snapshot.toJSON()).find(x => x.name === packageName))
        })
    }, [packageName])

    return (
        <Container>
            <Helmet>
                <title>{`For Hajj | ${detail.name}`}</title>
            </Helmet>
            <Box mt={8}>
                <Grid Container>
                    <Breadcrumbs aria-label="breadcrumb" style={{ borderBottom: 'solid 1px green ' }}>
                        <Link color="inherit" href="/Home" >Home</Link>
                        <Link color="inherit" href="/hajj-package" >Hajj Packages</Link>
                        <Typography variant="body1">{detail.name}</Typography>
                    </Breadcrumbs>

                </Grid>

                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12} lg={4} sm={12} md={4}>
                        <div style={{ position: 'relative' }}>
                            <img src={kaaba} alt="" style={{ width: '100%', height: 'auto', border: '5px solid #ffffff' }} />
                            {detail.triplePrice &&
                                <div style={{ position: 'absolute', top: '15px', left: '-10px', backgroundColor: '#ff6f00', padding: '0.3rem', marginBottom: '25px' }}>
                                    <Typography variant="body1"  >{`${detail.triplePrice} Triple`}</Typography>
                                </div>
                            }
                            {detail.doublePrice &&
                                <div style={{ position: 'absolute', top: '30px', left: '-10px', backgroundColor: '#ff6f00', padding: '0.3rem', marginTop: '25px' }}>
                                    <Typography variant="body1" >{`${detail.doublePrice} Double`}</Typography>
                                </div>
                            }
                        </div>
                        <Box>
                            {detail.fees && <Typography variant="subtitle1" align="center" style={{ backgroundColor: '#4caf50', border: '5px solid #ffffff', color: '#000000', padding: '7px' }}>{`All prices exclude ${detail.fees} sacrifice and visa fees`}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={8} sm={12} md={8}>
                        <PackageFeatures detail={detail} />
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} lg={4} sm={12} md={4}><img src={Nabawi} alt="" fluid={true} style={{ width: '100%', height: 'auto' }} /></Grid>
                    <Grid item xs={12} lg={4} sm={12} md={4}><img src={image06} alt="" fluid={true} style={{ width: '100%', height: 'auto' }} /></Grid>
                    <Grid item xs={12} lg={4} sm={12} md={4}><img src={image05} alt="" fluid={true} style={{ width: '100%', height: 'auto' }} /></Grid>
                </Grid>

                <PackageSchedule detail={detail} />
                <Notes />

                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} sm={12} md={12}><h4>Related Packages</h4></Grid>
                    <Grid item xs={12} lg={4} sm={12} md={4}><Paper elevation={3}><img src={image04} alt="" fluid={true} style={{ width: '100%', height: 'auto', }} /></Paper></Grid>
                    {/* <Grid item xs={12} lg={4} sm={12} md={4}><Paper elevation={3}><img src={image03} alt="" fluid={true} style={{ width: '100%', height: 'auto', }} /></Paper></Grid> */}
                    {/* <Grid item xs={12} lg={4} sm={12} md={4}><Paper elevation={3}><img src={image02} alt="" fluid={true} style={{ width: '100%', height: 'auto', }} /></Paper></Grid> */}
                </Grid>

            </Box>
        </Container>


    )
}

export default PackageDetails
