import React from 'react';
import PackageDetails from './PackageDetails'

function UmrahPackageDetail() {


  return (
    <PackageDetails />
  )
}

export default UmrahPackageDetail


