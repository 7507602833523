import { Box, Container, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { CompanyName } from './CompanyName';
import Developed from './Developed';
import visa from './visa.pdf';
import firebase from './firebaseapp';
import PayNow from './Images/PayNow.gif';
import Button from '@material-ui/core/Button';

const today = new Date();

function Footer() {
  const history = useHistory();

  const [trips, setTrips] = useState([])
  useEffect(() => {
    firebase.database().ref("protected/onlinePackage").once('value', snapshot => {
      setTrips(Object.values(snapshot.toJSON()))
    })
  }, [])

  return (


    <div style={{ backgroundColor: "#e8f5e9", borderTop: "2px solid #4caf50", marginTop: '15px' }}>
      <Container >
        <Router>
          <Grid container spacing={3} >

            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Box pt={3}>
                <Typography variant="h6" >Hajj</Typography>
                <Grid container direction="column">
                  {trips && trips.filter(x => x.gender === 'Hajj').map(t =>
                    <Grid item><Link onClick={() => {history.push(`/hajj-package/detail/${t.name}`);  window.scrollTo(0, 0)}} style={{ cursor: 'pointer' }} title={t.name}>{t.name}</Link></Grid>
                  )}
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Box pt={3}>
                <Typography variant="h6">Umrah</Typography>
                <Grid container direction="column">
                  {trips && trips.filter(x => x.gender === 'Umrah').map(t =>
                    <Grid item><Link onClick={() => {history.push(`/umrah-package/detail/${t.name}`);  window.scrollTo(0, 0)}} style={{ cursor: 'pointer' }} title={t.name}>{t.name}</Link></Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Box pt={3}>
                <Typography variant="h6">Forms</Typography>
                <Grid container direction="column" >

                  <Grid item>

                    <Link onClick={() => history.push('/documents')} style={{ cursor: 'pointer' }} title="">Required Documents</Link>
                  </Grid>

                  <Grid item>
                    <Link href={visa} title="">Visa Application</Link>
                  </Grid>
                  <Grid item>

                    <Link onClick={() => history.push('/disclaimer')} style={{ cursor: 'pointer' }} title="">Disclaimer</Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={3} md={3} xs={12} sm={12}>
              <Box pt={3}>
                <Typography variant="h6">Contact</Typography>
                <Grid item>
                  <Link href="tel:7322343628" title="Call For Hajj | Hajj and Umrah from USA">Call Us</Link>
                </Grid>
                <Grid item>
                  <Link href="mailto:toursanywhere@aol.com" title="">E-mail Us</Link>
                </Grid>
                <Grid item>
                <Button href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=J92NWSF8L5RGY' target="_blank"><img src={PayNow} fluid={true} className={Image} alt='make a payment to For Hajj | Hajj and Umrah from USA' title="Make a Payment to For Hajj | Hajj and Umrah from USA"></img></Button>
                </Grid>
              </Box>
            </Grid>

          </Grid>



        </Router>



        <Typography style={{ paddingTop: '15px', textAlign: 'center' }}><Link to='/home' style={{ textDecoration: 'none' }} exact>&copy; {today.getFullYear()} - <CompanyName></CompanyName> | Hajj and Umrah from USA</Link><br /><Developed></Developed></Typography>

      </Container>
    </div>

  )
}

export default Footer
