import { Container } from '@material-ui/core';
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import emailjs, { init } from 'emailjs-com';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState, useRef } from 'react';
import * as yup from 'yup';
import { FormControl, InputLabel, Select, MenuItem, Input, Button } from '@material-ui/core';
import countries from '../countries.json';
import gender from '../Gender.json';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';


init("user_Tl0SGyQGCyQlgnLGgTTER");

const PackName = 'Thanksgiving Umrah';

const validationSchema = yup.object().shape({
    name: yup.string()
        .min(2, "Too Short!")
        .max(25, "Too Long!"),
    company: yup.string()
        .min(2, "Too Short!")
        .max(30, "Too Long!"),
    phone: yup.string()
        .min(2, "Too Short!")
        .max(10, "Too Long!"),

})


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',

        },
    },
}));



const initialValues = {
    PackName: PackName,
    image1: "",
    image2: "",
    image3: "",
    name: "",
    Aname: "",
    gender: "",
    country: "",
    PNumber: "",
    Issued: "",
    BirthDate: "",
    IssueDate: "",
    ExpiryDate: "",
    BirthPlace: "",
    IDNumber: "",
    IDDate: "",
    IDExpiryDate: "",
    Profession: "",
    phone: "",
    email: "",
    message: "",
}

export default function HajjEconomy() {

    const [IssueDate, setIssueDate] = useState('');
    const [ExpiryDate, setExpiryDate] = useState('');
    const [BirthDate, setBirthDate] = useState('');
    const [IDDate, setIDDate] = useState('');
    const [IDExpiryDate, setIDExpiryDate] = useState('');
    const classes = useStyles();
    const [emailSent, setEmailSent] = useState(false);

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);

    const handleIssueDate = (event) => {
        setIssueDate(event.target.value);
    };

    const handleExpiryDate = (event) => {
        setExpiryDate(event.target.value);
    };

    const handleBirthDate = (event) => {
        setBirthDate(event.target.value);
    };

    const handleIDDate = (event) => {
        setIDDate(event.target.value);
    };

    const handleIDExpiryDate = (event) => {
        setIDExpiryDate(event.target.value);
    };

    const handleImageUpload1 = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImage1(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleImageUpload2 = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImage2(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleImageUpload3 = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImage3(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };


    const handleButtonClick1 = () => {
        fileInputRef1.current.click();
    };

    const handleButtonClick2 = () => {
        fileInputRef2.current.click();
    };

    const handleButtonClick3 = () => {
        fileInputRef3.current.click();
    };

    const handleSubmit = async (values) => {
        console.log(values);



        values.IDDate = IDDate;
        values.IDExpiryDate = IDExpiryDate;
        values.Issued = IssueDate;
        values.BirthDate = BirthDate;
        values.IssueDate = IssueDate;
        values.ExpiryDate = ExpiryDate;

        const attachments = [
            { filename: 'image1.jpg', path: values.image1, cid: 'image1' },
            { filename: 'image2.jpg', path: values.image2, cid: 'image2' },
            { filename: 'image3.jpg', path: values.image3, cid: 'image3' },
        ];

        const template = `
          <p>First Image:</p>
          <img src="cid:image1" alt="Uploaded Image 1" style="max-width: 300px;" />
      
          <p>Second Image:</p>
          <img src="cid:image2" alt="Uploaded Image 2" style="max-width: 300px;" />
      
          <p>Third Image:</p>
          <img src="cid:image3" alt="Uploaded Image 3" style="max-width: 300px;" />
        `;

        const emailParams = {
            // Set your email parameters (to, from, subject, etc.)
            // ...

            // Set the HTML content with embedded images
            html: template,

            // Attach the images as static attachments
            attachments: attachments.map((attachment) => ({
                ...attachment,
                data: attachment.path, // Set the path to the image file here
            })),
        };

        try {
            await emailjs.send("service_161dv1i", "template_pk5hssh", emailParams);
            setEmailSent(true);
        } catch (error) {
            console.log("Error sending email:", error);
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <Container>


            {!emailSent &&
                <div>

                    <div>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(formikProps) => {
                                const { errors, submitForm } = formikProps;

                                return (
                                    <Form className={classes.root} autoComplete="off">

                                        <div><h1>{PackName} Reservation</h1></div>

                                        <div style={{ backgroundColor: '#e8f5e9', padding: '20px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both' }}><Typography>Basic Information</Typography></div>

                                            <Grid container spacing={3}>


                                                <Grid item lg={12} xs={12}>

                                                    <Grid item hidden>
                                                        <div style={{ display: 'flex', justifyContent: 'center', maxHeight: '300px', marginTop: '10px' }}>
                                                            {image1 && <img src={image1} alt="Passport Upload" style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: '7px' }} />}
                                                        </div>
                                                    </Grid>
                                                    <Grid item hidden>
                                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both' }}>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageUpload1}
                                                                ref={fileInputRef1}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item hidden>
                                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both', textAlign: 'center' }}>
                                                            <Typography variant="caption" display="block" gutterBotto style={{ paddingTop: '3px', paddingBottom: '3px', color: 'red' }}>(Bio page only)</Typography>
                                                            <Button fullWidth variant="contained" color="primary" onClick={handleButtonClick1} style={{ paddingBottom: '3px' }}>
                                                                <AddAPhotoIcon style={{ paddingRight: '5px' }} />  Upload passport image
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>





                                            </Grid>

                                            <Grid container spacing={3}>
                                                {/* <Grid item lg={2} xs={12} hidden>

                                                    <Grid item hidden>
                                                        <div style={{ display: 'flex', justifyContent: 'center', maxHeight: '300px', marginTop: '10px' }}>
                                                            {image2 && <img src={image2} alt="Uploaded" style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: '7px' }} />}
                                                        </div>
                                                    </Grid>
                                                    <Grid item hidden>
                                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both' }}>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageUpload2}
                                                                ref={fileInputRef2}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item hidden>
                                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both', textAlign: 'center' }}>

                                                            <Button fullWidth variant="contained" color="primary" onClick={handleButtonClick2} style={{ paddingBottom: '3px' }}>
                                                                <AddAPhotoIcon style={{ paddingRight: '5px' }} />  Upload Passenger image
                                                            </Button>
                                                        </div>
                                                    </Grid>


                                                </Grid> */}
                                                <Grid item lg={6} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth name="name" required label="Full Name:" type="text" autoComplete="off" />
                                                        <ErrorMessage name="name" />
                                                    </div>

                                                    <div>
                                                        <Field as={TextField} fullWidth name="Aname" label="Arabic Name (optional: if present in passport)" type="text" autoComplete="off" />
                                                        <ErrorMessage name="Aname" />


                                                    </div>



                                                </Grid>
                                                <Grid item lg={6} xs={12}>

                                                    <div style={{ paddingTop: '6px' }}>

                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="gender">Gender</InputLabel>
                                                            <Field name="gender" required as={Select} id="country">
                                                                <MenuItem value="">Select Gender</MenuItem>
                                                                {gender.map((gender) => (
                                                                    <MenuItem key={gender.code} value={gender.name}>
                                                                        {gender.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage name="country" component="div" />
                                                        </FormControl>

                                                    </div>

                                                    <div style={{ paddingTop: '6px' }}>

                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="country">Country</InputLabel>
                                                            <Field name="country" required as={Select} id="country">
                                                                <MenuItem value="">Select Country</MenuItem>
                                                                {countries.map((country) => (
                                                                    <MenuItem key={country.code} value={country.name}>
                                                                        {country.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage name="country" component="div" />
                                                        </FormControl>

                                                    </div>

                                                </Grid>
                                            </Grid>
                                        </div>




                                        <div style={{ backgroundColor: '#e8f5e9', padding: '20px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px' }}>
                                            <div><Typography>Passport Information</Typography></div>
                                            <Grid container spacing={3}>

                                                <Grid item lg={6} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth name="PNumber" required label="Passport Number:" type="text" autoComplete="off" />
                                                        <ErrorMessage name="PNumber" />
                                                    </div>

                                                    <div>
                                                        <Field as={TextField} fullWidth name="Issued" required label="Issued At :" type="text" autoComplete="off" />
                                                        <ErrorMessage name="Issued" />

                                                        <div>
                                                            <InputLabel required htmlFor="BirthDate">Birth Date</InputLabel>
                                                            <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                                                <Input
                                                                    name="BirthDate"

                                                                    value={BirthDate}
                                                                    onChange={handleBirthDate}
                                                                    type="date"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </FormControl>

                                                        </div>
                                                    </div>



                                                </Grid>
                                                <Grid item lg={6} xs={12}>

                                                    <div style={{ paddingTop: '6px' }}>
                                                        <InputLabel required htmlFor="IssueDate">Passport Issue Date</InputLabel>
                                                        <FormControl fullWidth variant="standard" >

                                                            <Input
                                                                name="IssueDate"

                                                                value={IssueDate}
                                                                onChange={handleIssueDate}
                                                                type="date"
                                                                required
                                                                fullWidth
                                                            />
                                                        </FormControl>

                                                    </div>

                                                    <div style={{ paddingTop: '6px' }}>
                                                        <InputLabel required htmlFor="ExpiryDate">Passport Expiry Date</InputLabel>
                                                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                                            <Input
                                                                name="ExpiryDate"

                                                                value={ExpiryDate}
                                                                onChange={handleExpiryDate}
                                                                type="date"
                                                                required
                                                                fullWidth
                                                            />
                                                        </FormControl>

                                                    </div>

                                                    <div>
                                                        <Field as={TextField} fullWidth name="BirthPlace" required label="Birth Place:" type="text" autoComplete="off" />
                                                        <ErrorMessage name="BirthPlace" />
                                                    </div>

                                                </Grid>



                                            </Grid>
                                        </div>

                                        <div style={{ backgroundColor: '#e8f5e9', padding: '20px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px' }}>
                                            <div><Typography>Residential Permit Information (optional) </Typography></div>
                                            <Grid container spacing={3}>
                                                <Grid item lg={4} xs={12}>
                                                    <Field as={TextField} fullWidth name="IDNumber" label="ID Number:" type="text" autoComplete="off" />
                                                    <ErrorMessage name="IDNumber" />
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <InputLabel htmlFor="IDDate">ID Issue Date</InputLabel>
                                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                                        <Input
                                                            name="IDDate"

                                                            value={IDDate}
                                                            onChange={handleIDDate}
                                                            type="date"

                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={4} xs={12}>
                                                    <InputLabel htmlFor="IDExpiryDate">ID Expiry Date</InputLabel>
                                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">

                                                        <Input
                                                            name="IDExpiryDate"

                                                            value={IDExpiryDate}
                                                            onChange={handleIDExpiryDate}
                                                            type="date"

                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>


                                        </div>

                                        {/* <div style={{ backgroundColor: '#e8f5e9', padding: '20px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px' }}>
                                            <div><Typography>Upload your vaccine</Typography></div>
                                            <Grid item lg={12} xs={12}>

                                                <Grid item>
                                                    <div style={{ display: 'flex', justifyContent: 'center', maxHeight: '300px', marginTop: '10px' }}>
                                                        {image3 && <img src={image3} alt="Uploaded 1" style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: '7px' }} />}
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both' }}>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleImageUpload3}
                                                            ref={fileInputRef3}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ marginLeft: 'auto', marginRight: 'auto', clear: 'both', textAlign: 'center' }}>
                                                        <Button fullWidth variant="contained" color="primary" onClick={handleButtonClick3} style={{ paddingBottom: '3px', paddingTop: '5px' }}>
                                                            <AddAPhotoIcon style={{ paddingRight: '5px' }} />  Upload your vaccine
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>


                                        </div> */}



                                        <div style={{ backgroundColor: '#e8f5e9', padding: '20px', borderRadius: '7px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px' }}>

                                            <div><Typography>More Information</Typography></div>
                                            <Grid container spacing={2}>


                                                <Grid item lg={6} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth name="Profession" label="Profession:" type="text" autoComplete="off" />

                                                    </div>


                                                </Grid>
                                                <Grid item lg={6} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth name="phone" label="Phone:" type="Number" autoComplete="off" />

                                                    </div>





                                                </Grid>
                                                <Grid item lg={12} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth name="email" label="Email:" type="text" autoComplete="off" />

                                                    </div>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <div>
                                                        <Field as={TextField} fullWidth multiline rows={5} name="message" label="Message:" type="text" autoComplete="off" />

                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </div>

                                        <div style={{ marginTop: '25px' }}>

                                            <Button fullWidth variant="contained" type="submit" color="primary" onClick={submitForm}><SendIcon></SendIcon>&nbsp;&nbsp;<b>Submit</b></Button>
                                        </div>

                                    </Form>
                                );
                            }}

                        </Formik>

                    </div>




                </div>
            }
            {emailSent &&

                <div style={{ marginLeft: 'auto', marginRight: 'auto', justifyContent: 'center', }}>
                    <div><h1>{PackName} Reservation</h1>
                        <div>Thank you, we will be in touch soon</div>
                    </div>


                </div>
            }
        </Container>
    )
}
