import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CompanyName } from './CompanyName';

function Disclaimer() {

    return (



        <Container >
            <Helmet>
                <title>For Hajj Disclaimer</title>
            </Helmet>
            <h4><CompanyName></CompanyName> Disclaimer</h4>
            <Paper style={{ padding: '15px' }}>
                <Typography color={"primary"} body1>
                    The responsibility of “For Hajj” as the tour operator and/or their agents is limited.<br /><br />
        “For Hajj” acts only as agents for passengers in the making of arrangements for the airlines, hotels, busses, or any other services in connection with this tour and assumes no liability whatsoever for injury, damage, loss, accident, delay or irregularity which may be occasioned either by reason of defects through the acts or defaults of any company or person engaged in conveying the passengers or carrying out the arrangements of the tour or as a direct or indirect result of “acts of God”, dangers incident to the air, sea, fire, breakdown of machinery or equipment, acts of government or other authorities, de jure or de facto (de jure = in law, de facto = in practice) , acts of war, whether declared or not, hostilities, civil disturbances, strikes, riots, their pilferage, epidemics, quarantines, medical or customs regulations or from any causes beyond the control of “For Hajj”, or from any loss or damage resulting from improper passports, visas, or other documents.<br /><br />
        “For Hajj” will accept NO responsibility for loss or additional expenses due to delay or changes in schedule or other causes and will not be responsible for the failure to follow instructions, including, but not limited to check-in and check-out times and baggage handling. In the event of delay, it is the responsibility of the airline to determine exactly what procedure will be followed. The policy chosen by the carrier shall be based on their procedures and shall not be the responsibility of “For Hajj” and its affiliates, neither of which will be responsible for any person(s) missing any part of the program due to his/her negligence or delay or absenteeism at any time during tour, and will not be responsible for any additional expenses for participants to rejoin the tour. Moreover, no refund will be given for any service rendered.
        </Typography>

            </Paper>
        </Container>

    )
}

export default Disclaimer
